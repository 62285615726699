﻿@import "fonts";
@import "icon";

// Primary colors
$color-default: #ffffff;
$color-orange: #f18e00;
$color-orange-light: #f5a600;
$color-red-dark: #dd2a2c;
$color-red-light: #e44b1d;
$color-yellow-dark: #f8be00;
$color-yellow-light: #fbd500;
$color-red-orange: #e34b1e;

// Base
$font-text: "Roboto", sans-serif;
$default-line-height: rem(32px);

// Container
$container-max-width: rem(1488px);
$container-smallest-max-width: rem(718px);
$container-small-max-width: rem(912px);
$container-med-portrait-max-width: rem(1168px);
$container-med-max-width: rem(1392px);
$container-med-landscape-max-width: rem(1392px);
$container-large-max-width: rem(1488px);

// Grid
$grid-columns: 12;
$grid-gap: 3rem;
