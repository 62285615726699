﻿@mixin scroll-icon {
	content: "";
	position: absolute;
	left: 6.25vw;
	bottom: 0;
	transform: translateY(50%);
	background: url(../../images/scroll-icon.svg);
	background-repeat: no-repeat;
	width: rem(15px);
	height: rem(121px);
	background-size: cover;
	background-position: bottom;
	z-index: 2;
	transition: all .5s ease-in-out;

	&:hover, &:focus, &:active {
		@media(hover: hover) and (pointer: fine) {
			height: 300px;
			transition: all .5s ease-in-out;
		}
	}

	@include breakpoint(l) {
		height: rem(242px);
	}
}
