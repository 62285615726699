﻿.socials {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	&.abs {
		position: absolute !important;
		bottom: rem(32px);
		right: rem(24px);

		a {
			background-color: $color-orange-light !important;

			&:after {
				color: $color-red-orange !important;
			}

			&:hover, &:focus, &:active {
				background: lighten($color-orange-light, 10%) !important;

				&:after {
					color: lighten($color-red-orange, 10%) !important;
				}
			}
		}
	}

	a {
		&.linkedin {
			&:after {
				@include icon($icon-linkedin);
			}
		}

		&.facebook {
			&:after {
				@include icon($icon-facebook);
			}
		}

		&.youtube {
			&:after {
				@include icon($icon-youtube);
			}
		}

		&.twitter {
			&:after {
				@include icon($icon-twitter);
			}
		}

		&.instagram {
			&:after {
				@include icon($icon-instagram);
			}
		}
	}
}

footer .socials h4{
	display: block !important;
}

.contact-text-block {
	.socials {
		margin-top: rem(48px);
	}
}

.socials {
	h4 {
		font-size: rem(32px);
		line-height: rem(32px);
		font-weight: 400;
		color: #fbd100;
		width: 100%;
		margin-bottom: rem(16px);
		display: none;
	}

	a {
		position: relative;
		width: rem(48px);
		height: rem(48px);
		border-radius: 50%;
		margin-right: rem(10px);
		background-color: #fbd100;
		color: transparent;

		&:hover, &:focus, &:active {
			background: lighten(#fbd100, 10%);

			&:after {
				color: lighten($color-orange, 10%);
			}
		}

		&:after {
			position: absolute;
			color: $color-orange;
			content: "";
			font-size: rem(19px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.yellow-bg {
	.socials a {
		background-color: $color-default;

		&:after {
			color: $color-red-light;
		}

		&:hover, &:focus, &:active {
			background: darken($color-default, 10%);

			&:after {
				color: lighten($color-red-light, 10%);
			}
		}
	}
}