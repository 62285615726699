﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 28px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $color-default;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: flex;
	align-items: center;
	background: #e7581e;
	position: fixed;
	z-index: 1001;
	//transition: opacity $transition-time ease-in-out, background $transition-time ease-in-out, color $transition-time ease-in-out;
	height: 56px;
	width: 75%;
	padding: 0 10px;
	border: none;
	left: 50%;
	transform: translateX(-50%);
	right: 0;
	bottom: 0;
	cursor: pointer;
	opacity: 1;
	outline: none;
	border-radius: 28px;
	bottom: rem(32px);

	.hamburger-box, .hamburger-phone, .hamburger-mail {
		position: relative;
		width: math.div(100%, 3);
		height: 100%;
		display: flex;
		justify-content: center;
		color: transparent;
	}

	.hamburger-phone, .hamburger-mail {
		z-index: 3;

		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 1px;
			height: 100%;
			left: 0;
			top: 0;
			background: $color-default;
			opacity: .24;
		}

		&:after {
			position: absolute;
			display: flex;
			font-size: rem(22px);
			font-weight: 400;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: $color-default;
		}
	}

	.hamburger-phone {
		&:after {
			@include icon($icon-phone);
		}
	}

	.hamburger-mail {
		&:after {
			@include icon($icon-mail);
		}
	}

	@include breakpoint(l) {
		width: rem(72px);
		height: rem(72px);
		border-radius: 50%;
		top: rem(24px);
		right: rem(30px);
		border: none;
		bottom: unset;
		left: unset;
		transform: unset;

		.hamburger-box {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 28px;
		}

		.hamburger-phone, .hamburger-mail {
			display: none;
		}
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		background: #ee8106;
	}

	&:focus {
		background: #ee8106;
	}
}
