﻿$font-path: '../../fonts/';

@font-face {
	font-family: 'Roboto';
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
	src: url('#{$font-path}Roboto-ExtraLight.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	src: url('#{$font-path}Roboto-Light.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'roboto';
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	src: url('#{$font-path}Roboto-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	src: url('#{$font-path}Roboto-Medium.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	src: url('#{$font-path}Roboto-Bold.woff2') format('woff2');
	font-display: swap;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(18px);
	line-height: rem(32px);
	font-weight: 300;
}

@mixin font-button {
	font-size: rem(20px);
	line-height: rem(32px);
	font-weight: 400;
}

@mixin font-footer-li {
	font-size: rem(24px);
	line-height: rem(24px);
	font-weight: 300;
	color: $color-default;
}

@mixin font-footer-legal {
	font-size: rem(14px);
	line-height: rem(14px);
	font-weight: 400;
	color: $color-yellow-light;
}

@mixin font-dropdown {
	font-size: rem(18px);
	line-height: rem(18px);
	font-weight: 400;
}

@mixin font-overview {
	font-size: rem(20px);
	line-height: rem(32px);
	font-weight: 400;
	color: $color-default !important;
}

@mixin font-instagram-feed {
	font-weight: 300;
	font-size: rem(16px);
	line-height: rem(24px);
	color: $color-default;
}

@mixin font-toastr {
	font-size: rem(20px);
	line-height: rem(32px);
	color: $color-red-light;
	font-weight: 400;
}

@mixin font-menu-navigation {
	font-size: rem(32px);
	line-height: rem(40px);
	font-weight: 200;
	color: $color-default;

	@include breakpoint(l) {
		font-size: rem(40px);
		line-height: rem(48px);
	}
}
