﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/scrollbar/scrollbar';
@import '~swiper/components/effect-fade/effect-fade';

//swiper
.swiper-scrollbar {
	position: absolute;
	width: 100px !important;
	height: 4px;
	z-index: 1;
	background-color: rgba($color-red-light, 0.2);
	left: 50%;
	transform: translateX(-50%);

	.swiper-scrollbar-drag {
		background-color: $color-red-light !important;
	}
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	background: #f6af00 !important;

	&:after {
		color: #f18e00 !important;
	}
}

.swiper-container {
	position: relative;
}

.swiper-pagination {
	padding-top: rem(16px);
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	text-align: center;
	line-height: 32px;
	font-size: 24px;
	font-weight: 700;
	opacity: 1;
	background: #fbd100;
	border: none;
	margin: 0 rem(10px) 0 0 !important;
	opacity: .25;
}

.swiper-pagination-bullet:hover {
	//background: $color-green-2;
}

.swiper-pagination-bullet-active {
	opacity: 1;
}

.swiper-btns {
	@media screen and (max-width: 1439px) {
		display: none;
	}

	position: absolute;
	width: 100%;
	top: 50%;
	z-index: 1;

	.swiper-button-prev {
		left: 0;

		&:after {
			@include icon($icon-arrow-left);
		}
	}

	.swiper-button-next {
		top: rem(-92px);
		left: 0;

		&:after {
			@include icon($icon-arrow-right);
		}
	}

	.swiper-button-prev, .swiper-button-next {
		position: absolute;
		width: rem(72px);
		height: rem(72px);
		background-color: #fbd100;
		opacity: 1;
		border-radius: 50%;
		transform: translateX(-50%);
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-osx-font-smoothing: grayscale;
		-webkit-transition: color 300ms;
		transition: color 300ms;

		&:after {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(17px);
			color: #f29500;
		}

		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: lighten($color-orange, 10%);
			border-radius: 100%;
			-webkit-transform: scale(0);
			transform: scale(0);
			-webkit-transition-property: transform;
			transition-property: transform;
			-webkit-transition: 300ms ease-out;
			transition: 300ms ease-out;
		}

		&:hover:before {
			-webkit-transform: scale(2);
			transform: scale(2);
		}

		&:hover:after {
			transition: 300ms ease-out;
			color: #fbd100;
		}
	}
}

.yellow-bg {

}