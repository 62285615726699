$icon-arrow-down: '\ea01';
$icon-arrow-left: '\ea02';
$icon-arrow-right: '\ea03';
$icon-arrow-thin-down: '\ea04';
$icon-arrow-thin-left: '\ea05';
$icon-arrow-thin-right: '\ea06';
$icon-arrow-thin-up: '\ea07';
$icon-arrow-up: '\ea08';
$icon-close: '\ea09';
$icon-country: '\ea0a';
$icon-facebook: '\ea0b';
$icon-instagram: '\ea0c';
$icon-linkedin: '\ea0d';
$icon-mail: '\ea0e';
$icon-phone: '\ea0f';
$icon-play: '\ea10';
$icon-quote: '\ea11';
$icon-twitter: '\ea12';
$icon-youtube: '\ea13';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-thin-down:before { content: $icon-arrow-thin-down; }
.icon-arrow-thin-left:before { content: $icon-arrow-thin-left; }
.icon-arrow-thin-right:before { content: $icon-arrow-thin-right; }
.icon-arrow-thin-up:before { content: $icon-arrow-thin-up; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-close:before { content: $icon-close; }
.icon-country:before { content: $icon-country; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-mail:before { content: $icon-mail; }
.icon-phone:before { content: $icon-phone; }
.icon-play:before { content: $icon-play; }
.icon-quote:before { content: $icon-quote; }
.icon-twitter:before { content: $icon-twitter; }
.icon-youtube:before { content: $icon-youtube; }
