﻿.languages {
	opacity: 0;
	transition: opacity .25s ease-in-out;
	width: 85%;
	margin-top: rem(40px);

	@include breakpoint(l) {
		width: 65%;
	}

	@include breakpoint(l) {
		width: 45%;
	}

	label, li {
		background-color: #ee8106 !important;
		font-size: rem(18px) !important;
		line-height: rem(18px) !important;
		font-weight: 300 !important;
		width: rem(236px) !important;

		&:hover {
			background-color: lighten(#ee8106, 5%) !important;
		}
	}

	label {
		padding-left: rem(55px) !important;

		&:before {
			@include icon($icon-country);
			position: absolute;
			display: flex;
			font-size: rem(24px);
			font-weight: 200;
			left: rem(28px);
			top: 50%;
			transform: translate(-50%, -50%);
			color: #f6b203;
		}
	}
}
