﻿.instagram-feed {
	position: relative;

	.swiper-container {
		position: relative;
		margin-bottom: rem(28px);

		&:after {
			@include icon($icon-instagram);
			position: absolute;
			font-size: rem(48px);
			left: rem(24px);
			bottom: rem(24px);
			text-indent: 0;
			color: $color-default;
			z-index: 1;
		}

		.swiper-slide figure {
			position: relative;

			figcaption {
				@include font-instagram-feed;
				position: absolute;
				padding: 0 rem(48px) 0 rem(96px);
				bottom: rem(24px);
				z-index: 1;
			}
		}
	}

	.swiper-scrollbar {
		margin-bottom: rem(28px);
	}
}

.feed-button {
	position: relative;

	&:before {
		@include icon($icon-instagram);
		position: absolute;
		color: #fbd500;
		font-size: rem(16px);
		top: 68%;
		left: 0;
		transform: translateY(-50%);
		text-indent: 0;
		z-index: 1;
	}

	margin-bottom: rem(24px);

	@include breakpoint(l) {
		margin-bottom: 0;
	}
}
