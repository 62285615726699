﻿.toast {
	position: fixed;
	z-index: 100;
	top: rem(120px) !important;
	right: rem(32px);
	width: 100%;
	max-width: 90vw;
	left: rem(24px);
	padding: rem(21px) rem(74px) rem(25px) rem(32px);
	background: $color-yellow-dark;
	@include font-toastr;
	cursor: pointer;
	opacity: 0;
	transform: translateY(-15px);
	transition: right .25s ease-in-out, opacity .25s ease-in-out, background .25s ease-in-out;
	overflow: hidden;
	border-top-left-radius: rem(32px);
	border-bottom-right-radius: rem(32px);

	&:hover {
		background: darken($color-yellow-dark, 5%);
	}

	@include breakpoint(ts) {
		width: 100%;
		max-width: rem(420px);
		left: unset;
		right: rem(28px);
	}

	@include breakpoint(l) {
	}

	&.on {
		opacity: 0.95;

		@include breakpoint(l) {
			opacity: 1;
		}

		&::after {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;
			height: rem(4px);
			animation: width 8000ms;
			background: rgba($color-red-light, 0.5);
			content: '';
		}

		&:hover,
		&:focus {

			&::after {
				animation: nonexistent; // Setting the animation to a nonexistent animation resets it
			}
		}
	}

	.toast__title {
		@include font-toastr;
		margin-bottom: 0 !important;
	}

	.toast-close {
		display: none;
		position: absolute;
		top: rem(7px);
		right: rem(16px);
	}
}

@keyframes width {
	0% {
		width: 100%;
	}

	100% {
		width: 0;
	}
}
