﻿.contact-form {
	.has-img {
		padding-bottom: 0;

		@include breakpoint(l) {
			padding-top: 0;
		}
	}

	.is-image {
		position: relative;
	}

	figure {
		height: 100%;
		overflow: visible !important;

		img {
			object-position: bottom;
			z-index: 1;
		}
	}

	.column.is-text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint(l) {
			padding-right: rem(26px);
		}
	}
}

.form {
	input[type=checkbox] {
		position: relative;
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		width: rem(20px) !important;
		height: rem(20px) !important;
		outline: none !important;
		background-color: $color-default;
		border-radius: 0 !important;
		border: none;
		margin: 0;
		top: rem(5px);
		padding: unset !important;

		&:checked {
			background-color: $color-yellow-dark;

			&:before {
				content: '\2713';
				display: block;
				text-align: center;
				color: $color-default;
				position: absolute;
				font-size: rem(11px);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	input, textarea {
		width: 100%;
		border-radius: rem(24px);
		border: none;
		margin-bottom: rem(10px);
		padding: rem(12px) 0 rem(12px) rem(29px);
	}

	textarea {
		padding-top: rem(14px) !important;
	}

	input::placeholder, textarea::placeholder, input, textarea {
		font-size: rem(18px);
		line-height: rem(18px);
		font-weight: 400;
	}

	textarea {
		height: rem(120px);
		margin-bottom: rem(6px);
	}

	input:focus, textarea:focus {
		outline: none;
	}

	input[type=checkbox] {
		width: auto;
	}

	.buttons {
		@media only screen and (min-width: 1024px) {
			//display: flex;
			//justify-content: right;
		}
	}

	button {
		border: none !important;
		background: none !important;
		margin-right: 0;
		padding-left: 0;
	}

	.email2 {
		display: none;
	}

	.dataconsent {
		margin-bottom: rem(16px);

		label {
			display: none;
		}

		p {
			position: relative;
			margin: rem(-27.2px) 0 0 rem(32px);

			@media only screen and (min-width: 1024px) {
				margin: rem(-27.2px) 0 0 rem(32px);
			}

			font-size: rem(16px);
			line-height: rem(26px);
		}

		div {
			position: relative;
			width: 100%;
		}
	}
}
