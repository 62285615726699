﻿.news-feed {
	figure, .placeholder {
		position: relative;

		figcaption, .caption {
			position: absolute;
			padding: 0 rem(40px);
			bottom: rem(48px);
			z-index: 1;
			pointer-events: none;

			p {
				@include font-overview;
				font-size: rem(24px) !important;
				margin-bottom: unset;
			}
		}
	}
}
