﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-4by3 {
		@include responsive-container-psudo(4, 3);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	&.is-075by1 {
		@include responsive-container-psudo(0.75, 1);
	}

	&.is-082by1 {
		@include responsive-container-psudo(0.82, 1);
	}

	&.is-header-text {
		@include responsive-container-psudo(2.46, 1);
	}

	&.is-header-home {
		@include responsive-container-psudo(1.43, 1);
	}

	&.is-slider-ratio {
		@include responsive-container-psudo(1.37, 1);
	}

	img, &div, video {
		width: 100%;
		height: auto;
	}

	&.overview-gradient {
		&:after {
			background-image: -moz-linear-gradient(0, rgb(221,42,44) 0%, rgba(221,42,44,0) 100%);
			background-image: -webkit-linear-gradient(0, rgb(221,42,44) 0%, rgba(221,42,44,0) 100%);
			background-image: -ms-linear-gradient(0, rgb(221,42,44) 0%, rgba(221,42,44,0) 100%);
			background-image: linear-gradient(0, rgb(221,42,44) 0%, rgba(221,42,44,0) 100%);
			opacity: 0.8;
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			width: 100%;
			height: rem(257px);
			z-index: 0;
			pointer-events: none;
			transition: all .375s ease-in-out;
		}

		&:hover {
			&:after {
				height: 25%;
				transition: all .375s ease-in-out;
			}
		}
	}

	&.has-hover {
		img {
			transition: all .25s ease-in-out;

			@media(hover: hover) and (pointer: fine) {
				&:hover {
					transform: scale(1.1);
					transition: all .375s ease;
				}
			}
		}
	}

	&.placeholder {
		background-image: url(../../images/placeholder.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&.has-spot {
		&:after {
			position: absolute;
			content: "";
			width: 140%;
			height: 100%;
			left: 50%;
			top: 60%;
			transform: translate(-50%, -50%);
			background: radial-gradient(circle,rgba(255,255,255,.3),rgba(255,255,255,0) 70%);
			z-index: 0;
		}
	}
}
