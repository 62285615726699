﻿.button {
	@include font-button;
	@include underline;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	width: max-content;
	max-width: 100%;
	position: relative;
	z-index: 0;
	display: inline-flex;
	padding-right: rem(48px);
	margin: rem(16px) rem(24px) 0 0;
	overflow: visible;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	box-shadow: none !important;
	color: $color-default;

	&.reverse {
		padding-right: unset;
		padding-left: rem(32px);

		&:after {
			right: unset;
			left: rem(8px);
			@include icon($icon-arrow-left);
		}

		&:hover {
			background-position: -100% 0;

			&:after {
				//animation: spin-center-reverse .5s ease-in-out;
				//transform-origin: center;
				transition: all .25s ease-in-out;
				transform: translate(-50%, -50%);
			}
		}
	}

	&.vacancy {
		@media only screen and (max-width: 1023px) {
			font-size: rem(16px);
		}

		&:before {
			display: none;
		}

		&:hover {
			filter: brightness(95%);
		}
	}

	&::selection, &::-moz-selection {
		color: none;
		background: none;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background-position: -100% 0;

			&:after {
				transition: all .25s ease-in-out;
				transform: translate(30%, -50%);
				//transform-origin: center;
			}
		}
	}

	&:focus {
		outline: 0 !important;
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-red-orange;
			border-radius: 50%;
			border-top: rem(4px) solid lighten($color-red-light, 20%);
			width: rem(20px);
			height: rem(20px);
			top: 50%;
			left: rem(24px);
			margin-top: -10px;
			animation: spin 1.5s linear infinite;
			z-index: 2;
		}
	}

	&.feed {
		position: relative;
		padding-left: rem(32px);
	}

	&:after {
		@include icon($icon-arrow-right);
		position: absolute;
		display: flex;
		font-size: rem(16px);
		font-weight: 400;
		right: rem(8px);
		top: 55%;
		transform: translate(-50%, -50%);
		transition: all .25s ease-in-out;
	}

	.vacancies-count {
		@include font-button;
		width: rem(40px);
		height: rem(40px);
		border-radius: 50%;
		padding-top: rem(3px);
		display: flex;
		justify-content: center;
		margin: 0 rem(12px);
	}
}

.buttons {
	margin-top: rem(-16px);
	padding-bottom: rem(23px);

	.button:nth-child(1) {
	}

	.button:nth-child(2) {
	}
}