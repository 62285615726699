﻿.menu-button {
	position: relative;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	width: max-content;
	min-width: rem(257px);
	max-width: 100%;
	position: relative;
	z-index: 0;
	display: inline-flex;
	padding: rem(10px) rem(67px) rem(12px) rem(40px);
	overflow: hidden;
	cursor: pointer;
	align-items: center;
	box-shadow: none !important;
	border-radius: rem(28px);
	color: $color-default;
	background-image: linear-gradient(to right, $color-red-light 50%, $color-red-dark 50%);
	background-size: 200% 100%;
	transition: background-position .25s ease;
	font-weight: 400;
	font-size: rem(18px);

	&.delay-1, &.delay-2, &.delay-3 {
		opacity: 0;
	}

	&.delay-1 { transition-delay: 600ms !important;}
	&.delay-2 { transition-delay: 700ms !important;}
	&.delay-3 { transition-delay: 800ms !important;}

	&.contact {
		padding-left: rem(70px);
		padding-right: rem(40px);

		&:after {
			position: absolute;
			display: flex;
			font-size: rem(16px);
			font-weight: 400;
			left: rem(48px);
			top: 50%;
			transform: translate(-50%, -50%);
			color: $color-yellow-light;
		}
	}

	&.link {
		&:after {
			@include icon($icon-arrow-right);
			position: absolute;
			display: flex;
			font-size: rem(17px);
			font-weight: 400;
			right: rem(40px);
			top: 50%;
			transform: translate(-50%, -50%);
			color: $color-yellow-light;
		}
	}

	&.phone {
		&:after {
			@include icon($icon-phone);
		}
	}

	&.mail {
		&:after {
			@include icon($icon-mail);
		}
	}

	&::selection, &::-moz-selection {
		color: none;
		background: none;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background-position: -100% 0;
		}
	}

	&:focus {
		outline: 0 !important;
	}
}
