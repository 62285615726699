﻿@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateX(-550px);
	}

	50% {
		-webkit-transform: translateX(15px);
	}

	70% {
		-webkit-transform: translateX(-10px);
	}

	90%, 100% {
		-webkit-transform: translateX(0);
	}

	80% {
		-webkit-transform: translateX(-5px);
	}
}

@keyframes bounce {
	0% {
		transform: translateX(-550px);
	}

	50% {
		transform: translateX(15px);
	}

	70% {
		transform: translateX(-10px);
	}

	90%, 100% {
		transform: translateX(0);
	}

	80% {
		transform: translateX(-5px);
	}
}

@keyframes slide-to-left {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes bounceInLeft {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0);
	}

	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounceInLeft {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0);
	}

	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-center {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

@keyframes spin-center-reverse {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

.pulse {
	position: relative;
	text-decoration: none;
	animation: pulse 1500ms infinite;

	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 100%;
		box-shadow: 0 0 0 rgba($color-default, 0.75);
		transition: width 250ms ease-in-out, height 250ms ease-in-out, background-color 250ms ease-in-out;
	}

	&:after {
		color: #fff;
		font-size: 1.25rem;
	}

	.is-active & {
		&:before {
			background: $color-default;
			animation: none;
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba($color-default, 0.75);
	}

	70% {
		-webkit-box-shadow: 0 0 0 1.75rem rgba(204,169,44, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba($color-default, 0.75);
		box-shadow: 0 0 0 0 rgba($color-default, 0.75);
	}

	70% {
		-moz-box-shadow: 0 0 0 1.75rem rgba(204,169,44, 0);
		box-shadow: 0 0 0 1.75rem rgba(204,169,44, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}