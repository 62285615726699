﻿.bottom-breadcrumbs {
	.breadcrumbs .button {
		position: absolute;
		top: unset !important;
		bottom: rem(48px);
	}
}

.breadcrumbs {
	z-index: 10;

	.button {
		position: relative
	}

	.container {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}
