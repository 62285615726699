﻿.dropdown {
	position: relative;
	height: rem(56px);
	z-index: 3;

	ul, ol {
		padding-left: unset !important;

		li {
			list-style: unset !important;
		}
	}

	dl::selection {
		color: none;
		background: none;
	}

	dl {
		width: rem(265px);

		dt:focus {
			outline: 0;
		}

		a, label {
			position: relative;
			display: block;
			transition: all 0.25s ease;
			transition-delay: .25s;
			cursor: pointer;
		}

		dt label {
			@include font-dropdown;
			position: absolute;
			width: rem(312px);
			background: #e7581e;
			white-space: nowrap;
			cursor: pointer;
			outline: none !important;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			color: $color-default;
			border-radius: rem(28px);
			padding: rem(19px) rem(40px);
			z-index: 2;

			&:after {
				@include icon($icon-arrow-down);
				position: absolute;
				right: 16px;
				top: 52%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: $color-yellow-light;
				font-size: 13px;
				font-weight: 400;
				transition: all .15s ease-in-out;
			}

			&:hover, &:focus, &:active {
				background-color: lighten(#e7581e, 5%);
			}
		}

		dd {
			position: absolute;
			opacity: 0;
			pointer-events: none;
			margin-top: rem(55.2px);
			width: rem(312px);
			z-index: 2;

			ul {
				margin: 0 !important;

				&:before {
					display: none !important;
				}

				li {
					@include font-dropdown;
					list-style: none;
					padding: 0;
					background-color: #e7581e;

					a, label {
						color: $color-default;
						overflow: hidden;
						padding: rem(16px) rem(40px);
						text-align: left;
						text-overflow: ellipsis;
						z-index: 2;

						&:before {
							position: absolute;
							width: 100%;
							height: 1px;
							content: '';
							background: $color-default;
							opacity: .2;
							left: 0;
							top: 0;
						}
					}

					&:hover, &:focus, &:active {
						background-color: lighten(#e7581e, 5%);
					}
				}

				li:last-child {
					border-bottom-left-radius: 24px !important;
					border-bottom-right-radius: 24px !important;
				}
			}
		}
	}

	input[type=checkbox] {
		display: none;

		&:checked {
			~ dl dd {
				opacity: 1;
				pointer-events: all;
				transition: all 0.15s ease;
			}

			~ dl dt label {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				transition: all 0.05s ease;

				&:after {
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}
		}
	}
}

.filters {
	.dropdown {
		position: relative;

		dt label, dd {
			left: 50%;
			transform: translateX(-50%);
		}

		.dropdown-label {
			transition: color .1s ease-in-out !important;

			&:hover, &:focus, &:active {
				background-color: darken($color-default, 10%);
				transition: color .1s ease-in-out !important;
			}

			&:after {
				color: $color-red-orange;
			}
		}

		.button {
			position: absolute;
			left: 0;
			background: none;
			border: none;
		}

		li {
			transition: background-color .1s ease-in-out !important;

			&:hover, &:focus, &:active {
				background-color: darken($color-default, 10%);
				transition: background-color .1s ease-in-out !important;
			}
		}

		li a, label {
			color: $color-red-orange;
		}

		dl dd ul li, .dropdown-label {
			background-color: $color-default;
		}

		a, label {
			&:before {
				background: $color-red-orange;
			}
		}
	}
}