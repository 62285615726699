﻿footer {
	@extend %transition-footer;
	position: relative;
	background-color: $color-orange;
	z-index: 2;

	.footer-block {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding-top: rem(24px);
		padding-bottom: rem(30px);
		z-index: 1;

		.footer-navigation {
			@include font-footer-li;
			margin-bottom: rem(32px);

			@include breakpoint(l) {
				margin-bottom: 0;
				width: 38.5%;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin-bottom: rem(40px);

					a {
						@include underline;
					}
				}

				li:nth-child(odd) {
					width: 58%;
				}

				li:nth-child(even) {
					width: 42%;
				}

				li:last-child, li:last-child:nth-child(n-1) {
					margin-bottom: 0 !important;
				}
			}
		}

		.footer-logo {
			order: 4;
			width: rem(117px);
			height: rem(142px);
			margin-right: rem(125px);

			@include breakpoint(l) {
				order: 1;
			}

			img {
				object-fit: contain;
			}
		}

		.footer-socials {
			margin-bottom: rem(40px);

			@include breakpoint(l) {
				padding-top: rem(24px);
				margin-bottom: 0;
				width: 40%;
				order: 2;
			}
		}
	}

	.footer-links {
		@include font-footer-legal;
		padding-top: rem(27px);
		padding-bottom: rem(112px);
		z-index: 1;

		@include breakpoint(l) {
			padding-bottom: rem(11px);
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: rem(28px);
				margin-bottom: rem(16px);

				a {
					@include underline-small;
				}
			}
		}
	}

	.footer-elements {
		position: absolute;
		width: rem(650px);
		max-width: 100vw !important;
		height: rem(424px);
		bottom: 0;
		overflow: hidden;
		right: 0;

		@include breakpoint(l) {
			right: rem(-124px);
		}

		&:after {
			position: absolute;
			content: "";
			background: url(../../images/elementen-flipped.svg);
			width: rem(650px);
			max-width: 100vw !important;
			height: rem(640px);
			opacity: .1;
			right: 0;
			overflow: hidden;
			z-index: 0;
		}
	}

	h2 {
		position: relative;
		margin-bottom: rem(16px);
		padding-left: rem(24px);
		color: $color-default;

		@include breakpoint(slogan-dt) {
			position: absolute;
			margin-bottom: unset;
			right: 4vw;
			bottom: rem(160px);
			font-size: rem(40px);
			line-height: rem(40px);
			padding: unset;
			z-index: 1;
		}

		@include breakpoint(ds) {
			bottom: rem(116px);
			font-size: rem(56px);
			line-height: rem(56px);
			right: 8.33vw;
		}
	}
}
