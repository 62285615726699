﻿.articles {

	&.low-transition {
		.container {
			@include breakpoint(l) {
				padding-top: 0 !important;
			}
		}
	}

	h2, h3 {
		@include breakpoint(l) {
			max-width: 33%;
			width: auto;
			margin-left: auto;
			margin-right: auto;
		}

		@include breakpoint(ll) {
			max-width: 26%;
		}
	}

	&.feed-block {
		h2, h3 {
			@include breakpoint(l) {
				max-width: 46% !important;
			}
		}
	}

	h2 {
		@include breakpoint(l) {
			margin-bottom: rem(56px);
		}
	}

	.feeds {
		margin-bottom: rem(48px);

		@include breakpoint(l) {
			margin-bottom: rem(80px);
		}

		.news-feed {
			figcaption {
				@include breakpoint(l) {
					max-width: 55%;
				}
			}
		}
	}

	.overview {
		.column {
			margin-bottom: rem(48px);
			z-index: 2;
		}

		figure, .placeholder {
			position: relative;

			figcaption, .caption {
				position: absolute;
				padding: 0 rem(40px);
				bottom: rem(32px);
				z-index: 1;
				pointer-events: none;

				p {
					@include font-overview;
					margin-bottom: unset;
				}
			}
		}
	}
}

.articles-more {
	margin-top: rem(-64px);

	.button, p {
		margin: unset;
		color: $color-default !important;

		&:before {
			display: none;
		}
	}

	ul {
		padding: unset;
	}

	li {
		list-style: none !important;

		@media(hover: hover) and (pointer: fine) {

			a:hover {
				filter: brightness(90%);
			}
		}

		position: relative;
		padding-top: rem(11.5px);
		padding-bottom: rem(11.5px);

		&:before {
			top: 0;
		}
	}

	li:before, li:last-child:after {
		position: absolute;
		content: "";
		left: 0;
		background: $color-yellow-dark;
		height: 1px;
		width: 100%;
	}

	li:last-child:after {
		bottom: 0;
	}
}