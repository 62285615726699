﻿.parallax-up, .parallax-down {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
	height: 100%;
	pointer-events: none;

	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		content: "";
		background-size: contain !important;
		background-repeat: no-repeat !important;
		pointer-events: none;
	}
}

.animated-text-block, .contact-form {
	.parallax-up, .parallax-down {
		z-index: 1;

		&:after {
			@include breakpoint(t) {
				left: 50%;
			}
			width: rem(820px);
			height: rem(447px);
		}
	}

	.parallax-up {
		top: 70%;

		&:after {
			background: url(../../images/parallax/leaves-up.png);
			left: 90%;

			@include breakpoint(ts) {
				left: 60%;
			}
		}
	}

	.parallax-down {
		top: -25%;
		left: 48%;

		&:after {
			background: url(../../images/parallax/leaves-down.png);
			left: 10%;

			@include breakpoint(ts) {
				left: 40%;
			}
		}
	}
}

.feed-block {
	@media screen and (max-width: 1023px) {
		.parallax-up {
			opacity: 0;
		}
	}

	.parallax-up {
		top: 75%;
		left: 53.6%;

		&:after {
			width: rem(2040px);
			height: rem(1016px);
		}
	}

	.first-up {
		z-index: 3;

		&:after {
			background: url(../../images/parallax/flower-assortment-up.png);
		}
	}

	.second-up {
		z-index: 1;

		&:after {
			background: url(../../images/parallax/flower-assortment-down.png);
		}
	}
}

.call-to-action {
	.parallax-up {
		position: relative;
		top: -40%;
		left: 71%;
		max-height: 100%;
		height: rem(150px);
		margin-top: rem(-20px);

		&:after {
			width: rem(317.8px);
			height: rem(429px);
		}

		@include breakpoint(l) {
			left: 10%;
			height: 100%;
			margin-top: unset;

			&:after {
				width: rem(454px);
				height: rem(613px);
			}
		}


		&.yellow-flowers {
			&:after {
				background: url(../../images/parallax/flowers-yellow.png);
			}
		}

		&.red-flowers {
			&:after {
				background: url(../../images/parallax/flowers-red.png);
			}
		}
	}
}