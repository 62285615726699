﻿@use "sass:math";
%container,
.container {
	max-width: ($container-max-width + $grid-gap);
	padding: rem(90px) math.div($grid-gap, 2);
	margin: 0 auto;

	@include breakpoint(l) {
		padding: rem(156px) math.div($grid-gap, 2) rem(180px);
	}

	&.fluid {
		max-width: none;
		margin: 0;
	}

	&.smallest {
		max-width: ($container-smallest-max-width + $grid-gap);
	}

	&.small {
		max-width: ($container-small-max-width + $grid-gap);
	}

	&.med-landscape {
		max-width: ($container-med-landscape-max-width + $grid-gap);
	}

	&.med {
		max-width: ($container-med-max-width + $grid-gap);
	}

	&.med-portrait {
		max-width: ($container-med-portrait-max-width + $grid-gap);
	}

	&.large {
		max-width: ($container-large-max-width + $grid-gap);
	}
}
