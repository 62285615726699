﻿%transition {
	&:before {
		position: absolute;
		z-index: 1;
		content: '';
		min-width: 100%;
		width: 101vw;
		left: 0;
		background-size: cover;
		background-position: center;
		pointer-events: none;
		background-repeat: no-repeat;
	}
}

%transition-footer {
	@extend %transition;

	&:before {
		top: 0;
		height: 14.43vw;
		transform: translateY(-99%);
		background-image: url(../../images/footer-transition.svg);
		z-index: 0;

		@media only screen and (max-width: 1023px) {
			//transform: translateY(-80%);
		}
	}
}

%transition-header {
	@extend %transition;

	&:before {
		bottom: 0;
		height: 10.1vw;
		transform: translateY(99%);
		background-image: url(../../images/header-transition.svg);

		@media only screen and (max-width: 1023px) {
			//transform: translateY(-80%);
		}
	}
}

%transition-high {
	&:before {
		position: absolute;
		z-index: 1;
		content: '';
		min-width: 100%;
		width: 101vw;
		left: 0;
		background-size: cover;
		background-position: center;
		top: 0;
		height: 22.5vw;
		transform: translateY(-99.5%);
		pointer-events: none;
		background-repeat: no-repeat;
	}
}

%transition-high-yellow {
	@extend %transition-high;

	&:before {
		background-image: url(../../images/transitions/transition-high-yellow.svg);
	}
}

%transition-high-orange {
	@extend %transition-high;

	&:before {
		background-image: url(../../images/transitions/transition-high-orange.svg);
	}
}

%transition-high-red {
	@extend %transition-high;

	&:before {
		background-image: url(../../images/transitions/transition-high-red.svg);
	}
}

%transition-high-red-orange {
	@extend %transition-high;

	&:before {
		background-image: url(../../images/transitions/transition-high-red-orange.svg);
	}
}

%transition-low {
	&:before {
		position: absolute;
		z-index: 1;
		content: '';
		min-width: 100%;
		width: 101vw;
		left: 0;
		background-size: cover;
		background-position: center;
		top: 0;
		height: 10vw;
		transform: translateY(-99%);
		pointer-events: none;
		background-repeat: no-repeat;
	}
}

%transition-low-yellow {
	@extend %transition-low;

	&:before {
		background-image: url(../../images/transitions/transition-low-yellow.svg);
	}
}

%transition-low-orange {
	@extend %transition-low;

	&:before {
		background-image: url(../../images/transitions/transition-low-orange.svg);
	}
}

%transition-low-red {
	@extend %transition-low;

	&:before {
		background-image: url(../../images/transitions/transition-low-red.svg);
	}
}

%transition-low-red-orange {
	@extend %transition-low;

	&:before {
		background-image: url(../../images/transitions/transition-low-red-orange.svg);
	}
}