﻿.logo {
	position: absolute;
	width: rem(261px);
	height: rem(120px);
	display: flex;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;
	background-repeat: no-repeat;
	z-index: 1;
	margin: rem(32px) 0 0 rem(24px);
	background-image: url(../../images/logo.png);
	overflow: visible;

	@include breakpoint(l) {
		margin-left: 6.25vw;
	}

	&:after {
		position: absolute;
		content: "";
		width: 300%;
		max-width: 100vw;
		height: 200%;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
		background: radial-gradient(circle,rgba(255,221,111,.24),rgba(255,221,111,0) 30%);
		z-index: 0;
	}
}
