﻿.header {

	.breadcrumbs .container {
		padding-left: 0;
		padding-right: 0;
	}

	h1 {
		//transform: translateX(-550px);
		//animation: slide-in-right .5s ease-in-out forwards;
		animation: bounceInLeft 1s ease-in-out forwards;
	}

	&.is-home {
		.scroll-icon {
			@include scroll-icon;
			transform: translateY(100%);
			bottom: rem(291px);
			left: 6.25vw;

			&:hover, &:focus, &:active {
				@media(hover: hover) and (pointer: fine) {
					height: 280px !important;
				}
			}
		}

		position: relative;
		background-color: $color-red-light !important;
		min-height: rem(600px);

		.column:first-child {
			display: flex;
			align-items: center;
			padding-left: rem(24px);
			padding-top: rem(200px);
		}

		figure {
			transform: translateX(100%);
			height: 100%;
			z-index: 1;
			animation: slide-to-left .7s ease-out forwards;

			img {
				object-fit: contain;
				object-position: bottom;
			}
		}

		@include breakpoint(l) {
			min-height: 100vh;

			.columns {
				min-height: 100vh;
			}

			.column:first-child {
				padding-left: 6.25vw;
				padding-top: 0;
			}
		}

		&:after {
			position: absolute;
			content: "";
			background: url(../../images/elementen.svg);
			width: rem(1176px);
			height: rem(1157px);
			left: rem(-72px);
			bottom: rem(-355px);
			z-index: 0;
		}
	}

	&.is-text {
		.scroll-icon {
			@include scroll-icon;
			transform: translateY(100%);
			bottom: rem(48px);

			@include breakpoint(l) {
				bottom: 0;
			}
		}

		@include breakpoint(l) {
			h1 {
				padding-right: rem(160px);
			}
		}

		.header-container {
			position: relative;
			@extend %transition-header;
		}

		.container {
			padding-top: rem(236px);
			padding-bottom: rem(115px);
		}

		.column {
			position: relative;
		}

		h3 {
			position: absolute;
			top: rem(-32px);
			left: 0;
		}

		p {
			@include breakpoint(l) {
				padding-right: rem(48px);
			}
		}
	}
}
