﻿#menu {
	display: none;

	&:checked + .menu-toggler {
		@extend .is-active;
	}

	&:checked ~ .menu {
		top: 0;

		.menu__navigation {
			opacity: 1;

			li, .languages {
				opacity: 1;
				transition: opacity .25s ease-in-out;
			}
		}

		.menu__image {
			.menu-button, img {
				opacity: 1;
				transition: opacity .25s ease-in-out;
			}
		}
	}

	&:checked + #hamburger {
		opacity: 1;
		visibility: visible;
		background: #ee8106;
	}

	@media screen and (min-width: 1024px) {
		&:checked ~ #hamburger {
			.hamburger-inner {
				background-color: $color-default;

				&:before, &:after {
					background-color: $color-default;
				}
			}
		}
	}
}

.menu {
	position: fixed;
	z-index: 1000;
	width: 100%;
	top: -200%;
	transition: all 1s ease;
	background-color: $color-orange;

	.menu__container {
		position: relative;

		.column {
			position: relative;
		}

		.columns {
			height: 100vh;
			width: 100%;
			position: relative;
			z-index: 10;
		}

		.menu__column {
			display: none;

			@include breakpoint(l) {
				display: block;
			}
		}

		.menu__image {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;

			img {
				display: block;
				opacity: 0;
				transition-delay: 500ms !important;
			}

			figcaption {
				position: absolute;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				bottom: rem(40px);
				transform: translate(0, -50%);
				display: flex;
				justify-content: center;
				align-items: center;

				.menu-button:not(:last-child) {
					margin-bottom: rem(8px);
				}

				margin-left: 7.5vw;

				@include breakpoint(l) {
					margin-left: unset;
					top: 75%;
					bottom: unset;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			h2 {
				@include font-menu-navigation;
				display: none;
				justify-content: center;
				margin-bottom: rem(32px);

				@include breakpoint(l) {
					display: flex;
				}
			}
		}

		.menu__navigation {
			@include font-menu-navigation;
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			max-height: calc(100vh - 120px);

			/* width */
			.navigation::-webkit-scrollbar {
				width: 4px;
			}
			/* Track */
			.navigation::-webkit-scrollbar-track {
				background: $color-orange-light;
			}
			/* Handle */
			.navigation::-webkit-scrollbar-thumb {
				background: $color-yellow-dark;
			}

			.navigation {
				position: relative;
				overflow: auto;
				width: 85%;
				height: auto;
				max-height: 90vh;
				overflow-x: hidden;
				margin-top: 11.3vh;
				min-width: rem(320px);

				@include breakpoint(l) {
					width: 65%;
					margin-top: 22.6vh;
				}

				@include breakpoint(l) {
					width: 45%;
				}

				.list-label, label {
					&:hover {
						color: $color-yellow-light !important;
						font-weight: 200;
					}
				}

				.single-list {
					&:hover, &.active-true {
						span {
							color: $color-yellow-light !important;
							font-weight: 200;

							&:after {
								color: $color-yellow-light !important;
								font-weight: 200;
							}
						}
					}
				}

				.navhide {
					@include breakpoint(l) {
						display: none;
					}
				}

				input[type=checkbox] {
					display: none;

					&:checked {

						~ label {

							&:after {
								transform-origin: center !important;
								transform: translate(-50%, -50%) rotate(-180deg);
								transition: 150ms linear all !important;
							}
						}

						~ div {
							max-height: 9999px;
							margin-top: rem(8px);
							transition: max-height 0.5s ease-in-out;
						}
					}
				}

				.toggleable-list {
					position: relative;
					display: flex;
					flex-wrap: wrap;

					.drop-down {
						flex-basis: 100%;
					}

					.list-label {
						width: calc(100% - 59px);
						cursor: pointer;
					}

					&.active-true {
						.list-label {
							color: $color-yellow-light;
							font-weight: 300;
						}
					}

					li:last-child {
						&:after {
							display: none;
						}

						padding-bottom: rem(12px) !important;
					}

					li:before {
						display: none;
						max-height: rem(52px);
					}

					li, li a {
						max-height: rem(52px);
						padding-bottom: 16px;
					}
				}

				li:before {
					position: absolute;
					width: 100%;
					height: 1px;
					content: '';
					background: $color-default;
					opacity: .2;
					left: 0;
					top: 0;
					width: 100%;
				}

				li:last-child {
					&:after {
						position: absolute;
						width: 100%;
						height: 1px;
						content: '';
						background: $color-default;
						opacity: .2;
						left: 0;
						bottom: 0;
						width: 100%;
						z-index: 1;
					}
				}

				.drop-down-toggler {
					position: absolute;
					cursor: pointer;
					width: 35px;
					height: 35px;
					border-radius: 50%;
					top: 0;
					right: rem(16px);
					background: #ee8106;
					transform: translateY(-50%);
					margin-top: rem(32px);

					@include breakpoint(l) {
						width: 39px;
						height: 39px;
						margin-top: rem(36px);
					}

					&:after {
						color: $color-yellow-light;
						font-size: 16px;
						font-weight: 700;
						cursor: pointer;
						text-indent: 0;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					&:hover {
						background-color: $color-yellow-light !important;

						&:after {
							color: $color-orange !important;
						}
					}
				}

				.drop-down-toggler:after {
					@include icon($icon-arrow-down);
					transition: 150ms linear all !important;
				}

				li {
					position: relative;
					padding: rem(12px) 0;
					word-break: break-word;
					display: flex;
					opacity: 0;
					transition: opacity .25s ease-in-out;
					padding-left: rem(16px);

					span {
						max-width: calc(100% - 59px) !important;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					a {
						display: flex;
						align-items: center;
						width: 100%;
						height: 100%;
						margin: 0px;
					}
				}

				.drop-down li {
					padding-left: rem(24px);

					span {
						font-size: rem(24px);
						line-height: rem(27.5px);
						font-weight: 300 !important;
					}
				}
			}
		}
	}
}

.drop-down {
	max-height: 0;
	overflow: hidden;
}
