﻿section {
	position: relative;

	&.high-transition {

		&.yellow-bg, &.yellow-bg-only {
			@extend %transition-high-yellow;
		}

		&.orange-bg, &.orange-bg-only {
			@extend %transition-high-orange;
		}

		&.red-bg, &.red-bg-only {
			@extend %transition-high-red;
		}

		&.red-orange-bg, &.red-orange-bg-only {
			@extend %transition-high-red-orange;
		}
	}

	&.low-transition {

		&.yellow-bg, &.yellow-bg-only {
			@extend %transition-low-yellow;
		}

		&.orange-bg, &.orange-bg-only {
			@extend %transition-low-orange;
		}

		&.red-bg, &.red-bg-only {
			@extend %transition-low-red;
		}

		&.red-orange-bg, &.red-orange-bg-only {
			@extend %transition-low-red-orange;
		}
	}

	&.scroll-text-block {

		.container {
			@include breakpoint(l) {
				padding-top: 10vw !important;
				padding-bottom: 17.7vw !important;
			}
		}

		.panels {
			position: absolute;
			overflow: visible;
			height: 100%;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			opacity: 0;
			visibility: hidden;
		}

		.panel {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: translateY(100%);

			img {
				transition: all .1s ease-out;
			}
		}

		.panel-bg {
			img {
				transition: all .15s ease-in;
			}
		}

		figure {
			height: auto !important;
			width: auto;

			@media screen and (max-width: 1023px) {
				padding-top: rem(48px);
			}

			img {
				object-fit: contain !important;
				object-position: center;

				@include breakpoint(l) {
					height: auto !important;
					top: 50% !important;
					transform: translateY(-50%) !important;
				}

				width: auto !important;
			}

			@media screen and (max-width: 1023px) {
				margin: 0 auto;
				//width: 75%;

				img {
					object-position: top !important;
				}
			}
		}

		.column {
			position: relative;

			&.is-text {
				display: flex;
				flex-direction: column;
				justify-content: center;

				&.is-right {
					order: 2;

					@media screen and (max-width: 1023px) {
						order: 1 !important;
					}
				}

				&.is-left {
					order: 1 !important;
				}
			}

			&.is-image {
				&.is-left {
					order: 1;

					@media screen and (max-width: 1023px) {
						order: 2;
					}
				}

				&.is-right {
					order: 2 !important;
				}
			}
		}
	}

	&.animated-text-block {
		z-index: 1;

		.container {
			&.has-img {
				padding-top: 0;
				padding-bottom: 0;

				.is-text {

					@media screen and (max-width: 1279px) {
						padding-top: 7.5rem;
					}

					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}

		.column {
			&.is-text {
				z-index: 2;

				@include breakpoint(lm) {
					padding-right: rem(120px);
				}

				&.is-right {
					order: 2;

					@media screen and (max-width: 1279px) {
						order: 1 !important;
					}
				}

				&.is-left {
					order: 1 !important;
				}
			}

			&.is-image {
				position: relative;

				&.is-left {
					order: 1;

					@media screen and (max-width: 1279px) {
						order: 2;
					}
				}

				&.is-right {
					order: 2 !important;
				}

				figure {
					height: 100%;
					overflow: visible !important;

					img {
						object-position: bottom;
						z-index: 1;
					}
				}
			}
		}
	}

	&.text-blocks {
		padding-top: 0;
		padding-bottom: rem(80px);

		&.high-transition {
			@include breakpoint(l) {
				padding-top: rem(56px) !important;
			}
		}

		&.low-transition {
			@include breakpoint(l) {
				padding-top: 0 !important;

				.text-block:first-child {
					padding-top: 0 !important;
				}
			}
		}

		@include breakpoint(lm) {
			padding-top: rem(160px);
			padding-bottom: rem(160px);
		}

		h2.is-centered {
			margin-bottom: unset;
		}

		.text-block:last-child {
			padding-bottom: rem(32px) !important;

			@include breakpoint(l) {
				padding-bottom: 0 !important;
			}
		}

		&.extra-padding {
			@include breakpoint(l) {
				padding-bottom: rem(240px);
			}
		}

		.text-block {
			padding-top: rem(80px);
			padding-bottom: 0;

			@include breakpoint(lm) {
				padding-bottom: rem(80px);
			}

			&.med-landscape {
				@include breakpoint(lm) {
					.column:first-child {
						width: 65.5%;
					}

					.column:last-child {
						width: 34.5%;
					}

					.column.is-text.is-left {
						padding-right: rem(122px);
					}

					.column.is-text.is-right {
						padding-left: rem(122px);
					}
				}
			}

			&.med-portrait {
				@include breakpoint(lm) {
					.column:first-child {
						width: 47.26%;
					}

					.column:last-child {
						width: 52.74%;
					}

					.column.is-text.is-left {
						padding-right: rem(169px);
					}

					.column.is-text.is-right {
						padding-left: rem(169px);
					}
				}
			}

			.column {
				&.is-text {

					&.is-right {
						order: 2;

						@media screen and (max-width: 1279px) {
							order: 1 !important;
						}
					}

					&.is-left {
						order: 1 !important;
					}
				}

				&.is-image {
					&.is-left {

						@media screen and (max-width: 1279px) {
							order: 2;
						}
					}

					&.is-right {
						order: 2 !important;
					}
				}
			}

			figure {
				height: auto !important;
			}
		}
	}

	&.big-media-block {

		@include breakpoint(l) {
			.container {
				padding-bottom: 16.14vw;
			}
		}

		.image {
			margin-top: rem(32px);
		}

		.empty-video-block {
			background-color: darken($color-default, 10%);
		}

		figure, .empty-video-block {
			position: relative;
			cursor: pointer;
			overflow: hidden;

			&:hover {
				.video-button:before {
					-webkit-transform: scale(2);
					transform: scale(2);
				}
			}

			&:hover {
				.video-button:after {
					transition: 300ms ease-out;
					color: #fbd100;
				}
			}
		}

		.video-button {
			position: absolute;
			width: rem(120px);
			height: rem(120px);
			border-radius: 50%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background-color: $color-default;

			@media screen and (max-width: 1023px) {
				width: rem(72px);
				height: rem(72px);
			}

			&:after {
				@include icon($icon-play);
				position: absolute;
				font-size: rem(36px);
				left: 55%;
				top: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				background-repeat: no-repeat;
				color: #e34b1e;

				@media screen and (max-width: 1023px) {
					font-size: rem(21px);
				}

				transition: 300ms ease-out;
			}

			overflow: hidden;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-moz-osx-font-smoothing: grayscale;
			-webkit-transition: color 300ms;
			transition: color 300ms;

			&:before {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $color-orange;
				border-radius: 100%;
				-webkit-transform: scale(0);
				transform: scale(0);
				-webkit-transition-property: transform;
				transition-property: transform;
				-webkit-transition: 300ms ease-out;
				transition: 300ms ease-out;
			}
		}
	}

	&.half-block {

		&.extra-margin {
			margin-bottom: 4.3vw;
		}

		.scroll-icon {

			&.is-left {
				@include scroll-icon;
				transform: translateY(50%);
			}

			&.is-right {
				@include scroll-icon;
				transform: translateY(50%);
				left: unset;
				right: 6.25vw;
			}
		}

		.column {
			z-index: 0 !important;

			&.is-text {
				position: relative;
				display: flex;
				padding: rem(90px) math.div($grid-gap, 2) rem(58px);

				.text-content {
					max-width: 100%;
				}

				@include breakpoint(l) {
					padding: rem(208px) math.div($grid-gap, 2);

					.text-content {
						display: flex;
						justify-content: center;
						flex-direction: column;
						max-width: 71%;
						margin: 0 auto;
					}
				}

				&.is-right {
					@media screen and (max-width: 1023px) {
						order: 1;
					}
				}
			}

			&.is-image {
				figure {
					height: 100%;
				}

				&.is-left {
					@media screen and (max-width: 1023px) {
						order: 2;
					}
				}
			}
		}
	}

	&.slider-block {
		.column:first-child {
			padding: rem(80px) 6.25vw;

			@include breakpoint(l) {
				padding: rem(120px) 6.25vw;
			}
		}

		figure {
			position: relative;
			overflow: visible !important;
			height: 100%;
		}

		.swiper-slide {
			display: flex;
			height: auto;

			.columns {
				width: 100%;
			}
		}
	}

	&.call-to-action {

		@include breakpoint(l) {
			.extra-padding {
				padding-bottom: rem(240px);
			}
		}

		@media screen and (max-width: 1023px) {

			.columns {
				flex-direction: column-reverse;
			}

			.column:first-child {
				figure img {
					border-bottom-left-radius: rem(100px);
					border-top-right-radius: rem(100px);
				}
			}

			.column:last-child {
				border-top-left-radius: rem(100px);
				border-bottom-right-radius: rem(100px);
			}
		}

		h2 {
			@include breakpoint(ll) {
				font-size: rem(72px) !important;
				line-height: rem(72px) !important;
			}
		}

		h3 {
			font-size: rem(32px) !important;
			line-height: rem(32px) !important;
		}

		.column:first-child {
			figure img {
				border-bottom-left-radius: rem(200px);
				border-top-right-radius: rem(200px);
			}
		}

		.column:last-child {
			border-top-left-radius: rem(200px);
			border-bottom-right-radius: rem(200px);
			padding: rem(96px) rem(36px) rem(48px);

			@include breakpoint(l) {
				margin-top: rem(48px);
				padding: rem(140px) rem(90px);
			}

			@include breakpoint(ll) {
				padding: rem(180px) rem(120px);
			}
		}

		.buttons {
			padding-bottom: 0;
		}
	}

	&.contact-text-block {
		.column {
			margin-bottom: rem(40px);

			@include breakpoint(l) {
				margin-bottom: 0;
			}
		}

		.container {
			@include breakpoint(l) {
				padding-bottom: 17.7vw;
			}
		}
	}

	&.shortcuts-block {
		.contacts-overview {
			margin-top: rem(96px);

			@include breakpoint(ll) {
				margin-top: rem(64px);
			}
		}
	}

	&.shortcuts-block, &.vacancies {
		&.low-transition {
			.container {
				@include breakpoint(l) {
					padding-top: 0 !important;
				}
			}
		}

		.columns {
			@include breakpoint(ts) {
				padding-top: rem(48px);
			}
		}

		&.red-orange-bg {
			figcaption, .caption {
				background-color: $color-orange !important;
			}

			h4 {
				color: $color-default !important;
			}
		}

		.smallest:first-of-type {
			@include breakpoint(l) {
				padding-bottom: rem(64px) !important;
			}
		}

		.container.large {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}

		.container {
			.column {
				&.wide {
					margin-top: rem(-156px) !important;

					@include breakpoint(l) {
						margin-top: rem(-76px) !important;
					}
				}
			}

			.column:nth-child(2n) {
				@include breakpoint(l) {
					margin-top: rem(40px);
				}
			}

			.column {
				margin-bottom: rem(144px);
			}

			.column {
				position: relative;

				.image {
					position: relative;
					overflow: visible;
					z-index: 1;

					&.placeholder {
						border-bottom-left-radius: rem(80px);
						border-top-right-radius: rem(80px);
					}

					img {
						border-bottom-left-radius: rem(80px);
						border-top-right-radius: rem(80px);
					}

					figcaption, .caption {
						word-break: break-word;
						position: absolute;
						bottom: calc(100% - 60px);
						width: 100%;
						height: auto;
						padding: rem(42px);
						display: flex;
						justify-content: center;
						border-bottom-right-radius: rem(56px);
						border-top-left-radius: rem(56px);
						margin-left: rem(-16px);
						background-color: $color-red-orange;

						@include breakpoint(l) {
							margin-left: rem(-48px);
						}

						h4 {
							width: 100%;
							position: relative;
							color: $color-orange-light;
						}
					}
				}
			}
		}

		.filters {
			margin-bottom: rem(96px);

			@include breakpoint(l) {
				margin-top: rem(56px);
			}
		}

		.is-100 {
			padding-top: 0 !important;
			padding-bottom: rem(48px);

			h2 {
				margin-bottom: 0;
			}
		}

		.contacts {
			.column {
				margin-bottom: rem(216px);
				z-index: 2;
			}

			.overlay-btn {
				content: "";
				width: rem(120px);
				height: rem(120px);
				//background: rgba(#fff, 0.16);
				border-radius: 50%;
				border-top-right-radius: 0;
				position: absolute;
				bottom: rem(32px);
				left: rem(32px);

				.overlay-inner-btn {
					width: rem(72px);
					height: rem(72px);
					background: #fff;
					content: "";
					border-radius: 50%;
					border-top-right-radius: 0;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					transition: .15s ease;
					cursor: pointer;

					&:after {
						@include icon($icon-quote);
						font-size: rem(32px);
						color: $color-red-orange;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					span {
						display: none;
					}

					@media(hover: hover) and (pointer: fine) {
						&:hover {
							background: $color-yellow-light;
							transition: .15s ease;
						}
					}
				}
			}

			.overlay {
				position: absolute;
				top: 75%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				padding: 0 rem(24px);
				border-bottom-right-radius: 3.5rem;
				border-top-left-radius: 3.5rem;
				padding: rem(48px) rem(32px);
				background: $color-default;
				z-index: 4;
				transition: .25s ease-in-out;

				@include breakpoint(ds) {
					width: 120%;
				}

				&.overlay-hidden {
					opacity: 0;
					pointer-events: none;
					transition: .25s ease-in-out;
				}

				.overlay-content {
					pointer-events: none;

					p:last-child {
						margin-bottom: 0;
					}
				}

				.overlay-close {
					position: absolute;
					top: rem(10px);
					right: rem(10px);
					width: rem(32px);
					height: rem(32px);
					//background: $color-red-orange;
					border-radius: 50%;
					content: "";
					cursor: pointer;
					//transition: .15s ease;

					&:hover {
						//background: $color-yellow-light;
						//transition: .15s ease;

						&:after {
							color: $color-orange;
							transition: .15s ease;
						}
					}

					&:after {
						position: absolute;
						content: "";
						z-index: 1;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						@include icon($icon-close);
						font-size: rem(16px);
						color: $color-red-orange;
						transition: .15s ease;
					}
				}
			}

			figcaption {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				h4 {
					font-size: rem(32px);
					line-height: rem(32px);
					font-weight: 200;
					margin-bottom: rem(12px);
				}

				.function {
					position: relative;
					color: $color-orange-light;
					font-size: rem(18px);
					line-height: rem(18px);
					font-weight: 300;
					margin-bottom: rem(48px);
				}

				.buttons {
					padding-bottom: 0;
					padding-right: rem(48px);
				}

				.contact-link:first-child {
					margin-bottom: rem(12px);
				}

				.contact-link {
					color: $color-default;
					display: block;
					line-height: rem(18px);
					font-size: rem(18px);
					font-weight: 300;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.feed-block {
		.container {
			@include breakpoint(l) {
				padding-bottom: rem(360px);
			}
		}

		.news-feed {
			margin-bottom: rem(24px);
		}

		.feeds {
			position: relative;

			&:after {
				position: absolute;
				content: "";
				width: 200%;
				height: 200%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				background: radial-gradient(circle,rgba(255,255,255,.4),rgba(255,255,255,0) 30%);
				z-index: 0;
			}
		}

		.column {
			z-index: 2;
		}
	}
}
